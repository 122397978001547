import React from 'react'
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Layout = () => {

  return (
    <div className='flex flex-col justify-center items-center w-full min-h-screen'>
      <Navbar />
      <div className='grow flex justify-center w-full pt-[152px] md:pt-[90px]'>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export default Layout
