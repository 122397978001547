import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { zeroAddress } from 'viem';
import { useAccount } from 'wagmi';
import { readContract } from '@wagmi/core';
import { contractAddress } from '../../utils/config';
import { fetchNFTs } from '../../utils';
import { INFTInfo, IStakingPool } from '../../interfaces';
import bscLogo from '../../assets/images/chain-logos/bsc.svg';
import loopartCsdogeAbi from '../../abis/loopart-csdoge.abi.json';
import { toast } from 'react-toastify';

const MyAssets = () => {
    const { address } = useAccount();
    const [userNfts, setUserNfts] = useState<INFTInfo[]>([]);
    const [stakingPools, setStakingPools] = useState<IStakingPool[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialize = async (isInit?: boolean) => {
        if (isInit) {
            setIsLoading(true);
        }

        try {
            if (address) {
                const [nftsFetched, stakingPools] = await Promise.all([
                    fetchNFTs({ address }),
                    readContract({
                        address: contractAddress,
                        abi: loopartCsdogeAbi,
                        functionName: 'getStakingPools',
                        args: [
                            0,
                            100,
                            true,
                        ]
                    }),
                ]);

                const userNfts: INFTInfo[] = [];

                console.log('nftsFetched', nftsFetched);

                await Promise.all(
                    nftsFetched
                        .filter(x => x.contractType == 'ERC1155'
                            && x.tokenAddress.equals(contractAddress)
                        )
                        .map(async (item) => {
                            try {
                                userNfts.push({
                                    tokenId: BigInt(item.tokenId),
                                    amount: BigInt(item?.amount || 0),
                                    boughtAmount: BigInt(0),
                                    price: BigInt(0),
                                    categoryId: BigInt(((item.metadata as any).attributes as any[]).find(x => x.trait_type == 'category_id').value),
                                    paymentTokenAddress: zeroAddress,
                                    isNativePayment: true,
                                    name: (item?.metadata as any).name || '',
                                    description: (item?.metadata as any).description || '',
                                    tokenUri: item?.tokenUri || '',
                                    imgUri: (item?.metadata as any)?.image || '',
                                    creatorName: ''
                                })
                            } catch { }
                        }));

                console.log('userNfts', userNfts);
                setUserNfts(userNfts);
                setStakingPools(stakingPools as any);
            }
        } catch (e) {
            console.log('e: ', e);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, [address]);

    return (
        <div className='flex flex-col items-center w-full p-[20px_20px_30px] md:p-[30px_30px_50px]'>
            <div className='w-full max-w-[1336px]'>
                {
                    !isLoading ? (
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[20px] w-full mt-[20px]'>
                            {
                                userNfts.length > 0 ? (
                                    userNfts.map((item, index) => {
                                        const img = new Image();
                                        img.src = item.imgUri;

                                        let isHeightBiggerThanWidth = true;
                                        if (img.height < img.width) {
                                            isHeightBiggerThanWidth = false;
                                        }

                                        const poolId = stakingPools.filter(x => x.isActive).find(x => x.categoryId == item.categoryId)?.poolId || 0;

                                        return (
                                            <div key={index} className={`flex flex-col items-center w-[320px] h-[540px] bg-[#141324] rounded-[8px] mx-auto hover:scale-[1.01] transition-all duration-150`}>
                                                {/* NFT image */}
                                                <div className='flex justify-center items-center w-full h-[400px]'>
                                                    <Link to={`/details?tokenId=${item.tokenId.toString()}`} className='flex justify-center items-center w-full h-full'>
                                                        <img src={item.imgUri} className={`${isHeightBiggerThanWidth ? 'h-full max-w-full' : 'w-full'}`} />
                                                    </Link>
                                                </div>

                                                <div className='grow flex flex-col items-center w-full py-[10px] px-[20px]'>
                                                    {/* NFT name */}
                                                    <div className='w-full text-[20px] text-center font-semibold leading-[100%]'>{item.name}</div>

                                                    <div className='grow w-full text-[16px] mt-[15px]'>
                                                        {/* China logo */}
                                                        <div className='flex justify-between items-center w-full'>
                                                            <div className='flex justify-center items-center gap-[6px]'>
                                                                <span className='text-[#B5B5BA]'>Chain:</span>
                                                                <img src={bscLogo} className='w-[20px]' alt='bsc-logo' />
                                                            </div>

                                                            <div className='flex justify-center items-center gap-[6px]'>
                                                                <span className='text-[#B5B5BA]'>TokenId:</span>
                                                                <span>{`#${item.tokenId.toString()}`}</span>
                                                            </div>
                                                        </div>

                                                        {/* Amount */}
                                                        <div className='flex justify-between items-center gap-[6px]'>
                                                            <span className='text-[#B5B5BA]'>Amount:</span>
                                                            <span>
                                                                {item.amount.toString()}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <Link
                                                        to={`/staking-pool-detail?poolId=${poolId}`}
                                                        className={`flex justify-center items-center gap-[10px] w-full h-[30px] border border-solid border-[#99b7ff] rounded-full bg-[#2e2e49] hover:bg-[#99b7ff]`}
                                                        onClick={(e) => {
                                                            if (!poolId || poolId <= 0) {
                                                                e?.preventDefault();
                                                                return toast.warn('Staking pool does not exist for this NFT');
                                                            }
                                                        }}
                                                    >
                                                        <span>Go to stake</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className='col-span-1 md:col-span-2 lg:col-span-3 2xl:col-span-4 text-[#808080] text-center mt-[20px]'>No items</div>
                                )
                            }
                        </div>
                    ) : (
                        <div className='flex justify-center items-center gap-[20px] text-[#808080] text-center mt-[20px]'>
                            <span className='text-[26px]'>Loading...</span>

                            <TailSpin
                                height="32"
                                width="32"
                                color="#808080"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                    )
                }

            </div >
        </div >
    );
};

export default MyAssets;