import React from 'react';
import { socialItems } from '../../utils/data';
import logo from '../../assets/images/logo.png';

const Footer = () => {
    return (
        <footer className='flex flex-col justify-center items-center w-full shadow-[0px_0px_5px_#99b7ff]'>
            <div className='flex justify-center items-center w-full p-[20px]'>
                <div className='flex flex-col md:flex-row justify-between items-center gap-2 w-full max-w-[1280px]'>
                    <div className='flex justify-center items-center w-[80px] md:w-[130px]'>
                        <img src={logo} className='w-full' alt='logo' />
                    </div>

                    <div className='flex flex-col justify-center items-center gap-[10px] md:gap-[20px]'>
                        <div className='text-[20px] md:text-[24px] font-semibold leading-normal'>Community</div>

                        <div className='flex flex-wrap justify-center items-center gap-x-[30px] md:gap-x-[40px] gap-y-[10px] md:gap-y-[30px] max-w-full md:max-w-[230px]'>
                            {
                                socialItems.map((item, index) => {
                                    return (
                                        <a
                                            key={index}
                                            href={item.link}
                                            className='flex justify-center items-center w-[30px] md:w-[50px] h-[30px] md:h-[50px] md:p-[5px]'
                                            target={'_blank'}
                                            rel='noopener noreferrer'
                                        >
                                            <img src={item.icon} alt={item.title} />
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-center items-center w-full'>
                <div className='flex justify-center items-center w-full max-w-[1440px] h-[70px] border-t border-solid text-center px-[10px]'>
                    Copyright ©2021.All Rights Reserved By CSDOGE.
                </div>
            </div>
        </footer>
    );
};

export default Footer;