import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Web3Modal } from '@web3modal/react';
import { WagmiConfig } from 'wagmi';
import {
  defaultChain,
  ethereumClient,
  projectId,
  wagmiConfig
} from './utils/wagmi';
import Layout from './layouts/layout';
import Home from './pages/Home';
import Create from './pages/Create';
import Details from './pages/Details';
import StakingPools from './pages/Staking/StakingPools'
import bscLogo from './assets/images/chain-logos/bsc.svg';
import StakingPoolDetail from './pages/Staking/StakingPoolDetail';
import MyAssets from './pages/MyAssets';
import Admin from './pages/Admin';

function App() {
  return (
    <div className="App">
      <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<Layout />} >
              <Route path='/' element={<Home />} />
              <Route path='/create' element={<Create />} />
              <Route path='/details' element={<Details />} />
              <Route path='/staking-pools' element={<StakingPools />} />
              <Route path='/staking-pool-detail' element={<StakingPoolDetail />} />
              <Route path='/my-assets' element={<MyAssets />} />
              <Route path='/admin' element={<Admin />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </WagmiConfig>

      <Web3Modal
        themeMode='light'
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={defaultChain}
        chainImages={{
          '56': bscLogo,
          '97': bscLogo,
        }}
      />

      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        toastStyle={{
          top: '60px',
          fontSize: '20px'
        }}
      />
    </div>
  );
}

export default App;
