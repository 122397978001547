import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount } from 'wagmi';
import { adminAddress } from '../../utils/config';
import logo from '../../assets/images/logo.png';

const Navbar = () => {
    const { address } = useAccount();
    const { open } = useWeb3Modal();

    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        if (address?.toLowerCase() == adminAddress.toLowerCase()) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [address]);

    return (
        <nav className="fixed top-0 right-0 z-30 h-[154px] md:h-[92px] w-full p-[10px] transition-all duration-300 backdrop-blur-[4.5px] px-[20px] md:px-[30px]">
            <div className="flex justify-center items-center w-full h-full">
                <div className="flex flex-col md:flex-row justify-between items-center w-full max-w-[1536px] h-full">
                    {/* Logo image */}
                    <div className="flex justify-center items-center w-[50px] md:w-[80px]">
                        <Link to={'/'} className='flex justify-center items-center w-full'>
                            <img src={logo} className='w-full' alt='logo' />
                        </Link>
                    </div>

                    <div className='flex flex-col md:flex-row justify-center items-center gap-[10px] md:gap-[30px]'>
                        <div className='flex justify-center items-center order-2 md:order-1 gap-[10px] md:gap-[20px]'>
                            <Link to={'/'} className='text-[14px] md:text-[18px] text-center w-[80px] md:w-fit hover:text-[#99B7FF] font-zendots'>Home</Link>
                            <a
                                href={'https://csdoge.club/'}
                                className='text-[14px] md:text-[18px] hover:text-[#99B7FF] text-center w-[60px] md:w-fit font-zendots'
                                target={'_blank'}
                                rel='noopener noreferrer'
                            >
                                CS DOGE
                            </a>
                            <Link to={'/staking-pools'} className='text-[14px] md:text-[18px] text-center w-[80px] md:w-fit hover:text-[#99B7FF] font-zendots'>Staking Pools</Link>
                            {
                                address && (
                                    <Link to={'/my-assets'} className='text-[14px] md:text-[18px] text-center w-[80px] md:w-fit hover:text-[#99B7FF] font-zendots'>My Assets</Link>
                                )
                            }
                            <Link to={'/admin'} className={`text-[18px] hover:text-[#99B7FF] text-center font-zendots ${isAdmin ? 'hidden md:block' : 'hidden'}`}>Admin</Link>
                        </div>

                        {/* Connect wallet button */}
                        <button
                            className='flex justify-center items-center order-1 md:order-2 w-full md:w-[180px] h-[32px] md:h-[40px] text-[12px] md:text-[16px] font-semibold leading-normal border border-solid border-[#99b7ff] rounded-full bg-[#2e2e49] hover:bg-[#99b7ff]'
                            onClick={open}
                        >
                            {
                                address ? (
                                    `${address?.slice(0, 4)}...${address?.slice(-4)}`
                                ) : (
                                    'Connect wallet'
                                )
                            }
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;