import axios from 'axios';
import Moralis from 'moralis';
import { EvmChain } from '@moralisweb3/common-evm-utils';
import { isMainnet } from './config';

const key = process.env.REACT_APP_PINATA_KEY;
const secret = process.env.REACT_APP_PINATA_SECRET;
let moralisInstance: typeof Moralis;

interface IAttribute {
    trait_type: string;
    value: string | number;
}

interface IMetadata {
    name: string;
    description: string;
    image: File;
    attributes: IAttribute[];
}

interface IResult {
    success: boolean;
    imgHash?: string;
    message?: string;
}

interface IUploadResult {
    success: boolean;
    metadataUri?: string;
    imgUri?: string;
    message?: string;
}

interface IFetchParams {
    address: string;
    chainId?: number;
}

export const pinFileToIPFS = async (file: File): Promise<IResult> => {
    const url = "https://api.pinata.cloud/pinning/pinFileToIPFS";

    if (file) {
        try {
            const formData = new FormData();
            formData.append("file", file);
            const resFile = await axios({
                method: "post",
                url: url,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    'pinata_api_key': `${process.env.REACT_APP_PINATA_KEY}`,
                    'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_SECRET}`,
                }
            });

            const imgHash = resFile.data.IpfsHash;
            return { success: true, imgHash: imgHash };

        } catch (error) {
            return { success: false, message: "File pinning failed" };
        }
    } else {
        return { success: false, message: "File pinning failed" };
    }
};

export const pinJSONToIPFS = async (metadata: IMetadata): Promise<IUploadResult> => {
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;

    if (metadata.image) {
        const { success, imgHash } = await pinFileToIPFS(metadata.image);


        if (success) {
            const imgUri = "https://gateway.pinata.cloud/ipfs/" + imgHash;

            try {
                const rsp = await axios.post(
                    url,
                    { ...metadata, image: imgUri },
                    {
                        headers: {
                            pinata_api_key: key,
                            pinata_secret_api_key: secret,
                        }
                    }
                );

                return {
                    success: true,
                    metadataUri: "https://gateway.pinata.cloud/ipfs/" + rsp.data.IpfsHash,
                    imgUri: imgUri
                };
            } catch (e: any) {
                return {
                    success: false,
                    message: e.message,
                };
            }
        }
        else {
            return { success: false, message: 'JSON pinning failed' };
        }
    } else {
        return { success: false, message: 'Image file not provided' };
    }
};

export const fetchNFTs = async ({ address }: IFetchParams) => {
    if (!moralisInstance) {
        await Moralis.start({
            apiKey: process.env.REACT_APP_MORALIS_API_KEY,
        });

        moralisInstance = Moralis;
    }

    let chain: EvmChain;
    if (isMainnet) {
        chain = EvmChain.BSC;
    } else {
        chain = EvmChain.BSC_TESTNET;
    }

    const response = await Moralis.EvmApi.nft.getWalletNFTs({
        address,
        chain,
    });

    return response.result;
};

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));