import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { writeContract, readContract, waitForTransaction } from '@wagmi/core';
import { contractAddress } from '../../../utils/config';
import { INFTcategory } from '../../../interfaces';
import loopartCsdogeAbi from '../../../abis/loopart-csdoge.abi.json';

interface IProps {
    count: number;
    setCount: (count: number) => void;
}

const CategoryAdmin = ({ count, setCount }: IProps) => {
    const [nftCategories, setNftCategories] = useState<INFTcategory[]>([]);
    const [categoryName, setCategoryName] = useState<string>('');
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>();
    const [visibliity, setVisibility] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [isSetting, setIsSetting] = useState<boolean>(false);

    const initialize = async (isInit?: boolean) => {
        if (isInit) {
            setIsLoading(true);
        }

        try {
            const nftCategories = await readContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'getNFTCategories',
            });

            console.log('nftCategories', nftCategories);
            setNftCategories(nftCategories as any);
        } catch (e) {
            console.log('e: ', e);
        }

        setIsLoading(false);
    }

    const handleCreateCategory = async () => {
        if (!categoryName) {
            return toast.warn('Please input category name');
        }

        if (nftCategories.find(x => x.name.toLowerCase() === categoryName.toLowerCase())) {
            return toast.warn('Already existed name');
        }

        setIsCreating(true);

        try {
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'createCategory',
                args: [
                    categoryName,
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            setCount(count + 1);
            toast.success('Successful to create a new category');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to create a new category');
        }

        await initialize();

        setIsCreating(false);
    }

    const handleSetVisibility = async (categoryIndex: number) => {
        if (categoryIndex != selectedCategoryIndex) {
            return;
        }

        setIsSetting(true);
        try {
            const categoryId = Number(nftCategories[categoryIndex].id.toString());
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'setCategoryVisibility',
                args: [
                    categoryId,
                    visibliity,
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            setCount(count + 1);
            toast.success('Successful to set visibility');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to set visibility');
        }

        setSelectedCategoryIndex(undefined);
        setIsSetting(false);
    }

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, []);

    return (
        <div className='w-full'>
            <div className='flex justify-center items-center w-full text-[26px] font-bold'>Categories</div>

            <div className='flex justify-center items-center gap-[20px] w-full mt-[20px]'>
                <input
                    value={categoryName}
                    onChange={e => setCategoryName(e.target.value)}
                    placeholder='Input category name'
                    className='w-full max-w-[480px] h-[30px] p-[16px] border border-solid border-[#8E8E8E] rounded-[6px] bg-transparent outline-none'
                />
                <button
                    disabled={isCreating}
                    className='flex justify-center items-center gap-[10px] border border-solid border-[#8E8E8E] rounded-[6px] p-[3px_10px]'
                    onClick={handleCreateCategory}
                >
                    <span>Create New Category</span>
                    {
                        isCreating && (
                            <TailSpin
                                height="18"
                                width="18"
                                color="#ffffff"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        )
                    }
                </button>
            </div>

            {
                !isLoading ? (
                    nftCategories.length > 0 ? (
                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                    <div className="overflow-hidden border border-solid rounded-[8px]">
                                        <table className="table-auto min-w-full text-left text-sm font-light">
                                            <thead className="border-b font-medium">
                                                <tr>
                                                    <th scope="col" className="px-6 py-4">#</th>
                                                    <th scope="col" className="px-6 py-4">Category name</th>
                                                    <th scope="col" className="px-6 py-4 text-center">Visibility</th>
                                                    <th scope="col" className="px-6 py-4 text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    nftCategories.map((nftCategory, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="whitespace-nowrap px-6 py-4 font-medium">{index + 1}</td>
                                                                <td className="whitespace-nowrap px-6 py-4">{nftCategory.name}</td>
                                                                <td className="whitespace-nowrap px-6 py-4">
                                                                    <select
                                                                        value={nftCategory.isVisible ? 'show' : 'hidden'}
                                                                        className='flex justify-center items-center bg-transparent border border-solid border-[#99b7ff] rounded-full p-[5px_10px] mx-auto'
                                                                        onChange={e => {
                                                                            setNftCategories(current => {
                                                                                current[index].isVisible = e.target.value == 'show' ? true : false;
                                                                                return [...current];
                                                                            });
                                                                            setSelectedCategoryIndex(index);
                                                                            setVisibility(e.target.value == 'show' ? true : false);
                                                                        }}
                                                                    >
                                                                        <option value='show'>Show</option>
                                                                        <option value='hidden'>Hidden</option>
                                                                    </select>
                                                                </td>
                                                                <td className="whitespace-nowrap px-6 py-4">
                                                                    <button
                                                                        disabled={isSetting}
                                                                        className='flex justify-center items-center p-[2px_20px] text-[16px] font-semibold leading-normal border border-solid border-[#99b7ff] rounded-full bg-[#2e2e49] mx-auto'
                                                                        onClick={() => handleSetVisibility(index)}
                                                                    >
                                                                        <span>Set</span>
                                                                        {
                                                                            isSetting && index == selectedCategoryIndex && (
                                                                                <TailSpin
                                                                                    height="18"
                                                                                    width="18"
                                                                                    color="#ffffff"
                                                                                    ariaLabel="tail-spin-loading"
                                                                                    radius="1"
                                                                                    wrapperStyle={{}}
                                                                                    wrapperClass=""
                                                                                    visible={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='text-[#808080] text-center mt-[20px]'>No items</div>
                    )
                ) : (
                    <div className='text-[#808080] text-center mt-[20px]'>Loading...</div>
                )
            }
        </div>
    );
};

export default CategoryAdmin;