import twitterIcon from '../assets/images/social_logos/twitter.png';
import telegramIcon from '../assets/images/social_logos/telegram.png';
import mediumIcon from '../assets/images/social_logos/medium.png';
import instagramIcon from '../assets/images/social_logos/instagram.png';
import youtubeIcon from '../assets/images/social_logos/youtube.png';

export const socialItems = [
    {
        title: 'twitter',
        link: 'https://twitter.com/CSDOGE2',
        icon: twitterIcon
    },
    {
        title: 'telegram',
        link: 'https://t.me/csdogechat',
        icon: telegramIcon
    },
    {
        title: 'telegram',
        link: 'https://t.me/CSDOGE_DISCUTION_CHAT',
        icon: telegramIcon
    },
    {
        title: 'medium',
        link: 'https://medium.com/@caucasianshepherddoge',
        icon: mediumIcon
    },
    {
        title: 'instagram',
        link: 'https://www.instagram.com/csdoge2021/',
        icon: instagramIcon
    },
    {
        title: 'youtube',
        link: 'https://www.youtube.com/channel/UCZwrr_sL-ho8_EAGW9Ked4Q',
        icon: youtubeIcon
    },
]