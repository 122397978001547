import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { adminAddress } from '../../utils/config';
import CategoryAdmin from '../../components/Admin/CategoryAdmin';
import StakingPoolsAdmin from '../../components/Admin/StakingPoolsAdmin';

const Admin = () => {
    const navigate = useNavigate();
    const { address } = useAccount();
    const [count, setCount] = useState<number>(0);

    useEffect(() => {
        if (address?.toLowerCase() != adminAddress.toLowerCase()) {
            navigate('/');
        }
    }, [address]);

    return (
        <div className='flex flex-col items-center w-full mb-[50px]'>
            <div className='flex flex-col items-center w-full max-w-[1200px]'>
                <CategoryAdmin
                    count={count}
                    setCount={setCount}
                />
            </div>

            <div className='flex flex-col items-center w-full max-w-[1200px] mt-[50px]'>
                <StakingPoolsAdmin
                    count={count}
                />
            </div>
        </div>
    );
};

export default Admin;