import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoIosAddCircle } from "react-icons/io";
import { TailSpin } from 'react-loader-spinner';
import NFTCard from '../../components/Home/NFTcard';
import { writeContract, readContract, waitForTransaction } from '@wagmi/core';
import { useAccount } from 'wagmi';
import { adminAddress, contractAddress } from '../../utils/config';
import { INFTInfo, INFTcategory } from '../../interfaces';
import loopartCsdogeAbi from '../../abis/loopart-csdoge.abi.json';

const Home = () => {
    const { address } = useAccount();
    const [nftCategories, setNftCategories] = useState<INFTcategory[]>([]);
    const [nftInfos, setNftInfos] = useState<INFTInfo[]>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialize = async (isInit?: boolean) => {
        if (isInit) {
            setIsLoading(true);
        }

        try {
            const [nftInfos, nftCategories] = await Promise.all([
                readContract({
                    address: contractAddress,
                    abi: loopartCsdogeAbi,
                    functionName: 'getNFTInfos',
                    args: [
                        0,
                        100,
                        true,
                    ]
                }),
                readContract({
                    address: contractAddress,
                    abi: loopartCsdogeAbi,
                    functionName: 'getNFTCategories',
                })
            ]);

            console.log('nftInfos', nftInfos);

            setNftCategories(nftCategories as any);
            setNftInfos(nftInfos as any);
        } catch (e) {
            console.log('e: ', e);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, []);

    useEffect(() => {
        if (address?.toLowerCase() == adminAddress.toLowerCase()) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [address]);

    return (
        <div className='w-full p-[20px_20px] md:p-[50px_30px]'>
            <div className='flex flex-col gap-[50px] w-full max-w-[1800px]'>
                {
                    !isLoading ? (
                        nftCategories.length > 0 ? (
                            nftCategories.filter(x => x.isVisible).map((nftCategory, index) => {
                                return (
                                    <div key={index} className='w-full'>
                                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[30px] w-full'>
                                            <div className='w-[320px] h-[60px] mx-auto relative'>
                                                <div className='absolute top-0 left-0 flex items-center gap-[10px] md:gap-[20px] w-screen'>
                                                    {/* NFT category name */}
                                                    <div className='flex justify-center items-center w-[250px] md:w-fit h-fit md:h-[58px] py-2 text-[16px] md:text-[20px] text-center font-zendots font-medium leading-normal tracking-wide border border-solid border-[#99b7ff] rounded-full bg-[#2e2e49] px-[20px]'>{nftCategory.name}</div>

                                                    {/* Create CSDOGE button */}
                                                    {
                                                        isAdmin && (
                                                            <Link to={`/create?categoryId=${nftCategory.id.toString()}`}>
                                                                <IoIosAddCircle className='text-[#99B7FF] text-[60px] hover:scale-110 transition-all duration-150' />
                                                            </Link>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[30px] w-full mt-[30px]'>
                                            {
                                                nftInfos.filter(x => x.categoryId.toString() == nftCategory.id.toString()).length > 0 ? (
                                                    nftInfos.filter(x => x.categoryId.toString() == nftCategory.id.toString()).map((nftInfo, index) => {
                                                        return (
                                                            <NFTCard
                                                                key={index}
                                                                nftInfo={nftInfo}
                                                                initialize={initialize}
                                                            />
                                                        )
                                                    })
                                                ) : (
                                                    <div className='col-span-1 md:col-span-2 lg:col-span-3 2xl:col-span-4 text-[#808080] text-center'>No items in this category</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className='text-[#808080] text-center mt-[20px]'>No items</div>
                        )

                    ) : (
                        <div className='flex justify-center items-center gap-[20px] text-[#808080] text-center mt-[20px]'>
                            <span className='text-[26px]'>Loading...</span>

                            <TailSpin
                                height="32"
                                width="32"
                                color="#808080"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                    )
                }
            </div>
        </div >
    );
};

export default Home;