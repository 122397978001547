import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import { writeContract, waitForTransaction } from '@wagmi/core';
import { adminAddress, contractAddress, csdogeTokenAddress, csdogeTokenDecimals } from '../../utils/config';
import { INFTInfo } from '../../interfaces';
import loopartCsdogeAbi from '../../abis/loopart-csdoge.abi.json';
import bscLogo from '../../assets/images/chain-logos/bsc.svg';

interface IProps {
    nftInfo: INFTInfo;
    initialize: () => Promise<void>;
}

const NFTCard = ({ nftInfo, initialize }: IProps) => {
    const { address } = useAccount();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [amount, setAmount] = useState<number | null>(null);
    const [isBurning, setIsBurning] = useState<boolean>(false);
    const [isHeightBiggerThanWidth, setIsHeightBiggerThanWidth] = useState<boolean>(false);

    const handleBurn = async () => {
        if (!amount || amount < 0) {
            return toast.warn('Please input valid amount');
        }

        const remainingAmount = Number(nftInfo.amount.toString()) - Number(nftInfo.boughtAmount.toString());
        if (remainingAmount < amount) {
            return toast.warn('Insufficient balance');
        }

        setIsBurning(true);

        try {
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'burn',
                args: [
                    Number(nftInfo.tokenId.toString()),
                    amount
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            toast.success('Successful to burn');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to burn');
        }

        await initialize();

        setIsBurning(false)
    }

    const onImgLoad = ({ target: img }: any) => {
        setIsHeightBiggerThanWidth(img.offsetHeight > img.offsetWidth);
    }

    useEffect(() => {
        if (address?.toLowerCase() == adminAddress.toLowerCase()) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [address]);

    return (
        <div className={`flex flex-col items-center w-[320px] ${isAdmin ? 'h-[600px]' : 'h-[560px]'} bg-[#141324] rounded-[8px] mx-auto hover:scale-[1.01] transition-all duration-150`}>
            {/* NFT image */}
            <div className='flex justify-center items-center w-full h-[400px]'>
                <Link to={`/details?tokenId=${nftInfo.tokenId.toString()}`} className='flex justify-center items-center w-full h-full'>
                    <img src={nftInfo.imgUri} onLoad={onImgLoad} className={`${isHeightBiggerThanWidth ? 'h-full' : 'w-full'}`} />
                </Link>
            </div>

            <div className='grow flex flex-col items-center w-full py-[10px] px-[20px]'>
                {/* NFT name */}
                <div className='w-full text-[20px] text-center font-semibold leading-[100%]'>{nftInfo.name}</div>

                {/* Burn NFT */}
                <div className='grow flex justify-between items-center gap-[10px] w-full py-[10px]'>
                    {
                        isAdmin && (
                            <>
                                <input
                                    type='number'
                                    placeholder='Input number'
                                    min={0}
                                    step={1}
                                    value={amount ? amount : ''}
                                    className='grow h-[26px] bg-[#141324] border-b border-b-[#99b7ff] outline-none'
                                    onChange={e => setAmount(e.target.value ? Number(e.target.value) : null)}
                                />
                                <button
                                    disabled={isBurning}
                                    className='flex justify-center items-center gap-[5px] min-w-[55px] h-[26px] border border-solid border-[#99b7ff] rounded-[5px] bg-[#2e2e49] hover:bg-[#99b7ff]'
                                    onClick={handleBurn}
                                >
                                    <span>Burn</span>
                                    {
                                        isBurning && (
                                            <TailSpin
                                                height="18"
                                                width="18"
                                                color="#ffffff"
                                                ariaLabel="tail-spin-loading"
                                                radius="1"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        )
                                    }
                                </button>
                            </>
                        )
                    }
                </div>
                <div className='w-full text-[16px]'>
                    {/* China logo */}
                    <div className='flex justify-between items-center w-full'>
                        <div className='flex justify-center items-center gap-[6px]'>
                            <span className='text-[#B5B5BA]'>Chain:</span>
                            <img src={bscLogo} className='w-[20px]' alt='bsc-logo' />
                        </div>

                        <div className='flex justify-center items-center gap-[6px]'>
                            <span className='text-[#B5B5BA]'>TokenId:</span>
                            <span>{`#${nftInfo.tokenId.toString()}`}</span>
                        </div>
                    </div>

                    {/* Price */}
                    <div className='flex justify-between items-center gap-[6px]'>
                        <span className='text-[#B5B5BA]'>Price:</span>
                        <span>
                            {
                                nftInfo.isNativePayment ? (
                                    `${ethers.utils.formatEther(nftInfo.price)} BNB`
                                ) : (
                                    `${ethers.utils.formatUnits(nftInfo.price, csdogeTokenDecimals)} CSDOGE`
                                )
                            }
                        </span>
                    </div>

                    {/* Owner */}
                    <div className='flex justify-between items-center gap-[6px]'>
                        <span className='text-[#B5B5BA]'>Owned By:</span>
                        <span>{nftInfo.creatorName}</span>
                    </div>

                    {/* NFTs bought */}
                    <div className='flex justify-between items-center gap-[6px]'>
                        <span className='text-[#B5B5BA]'>NFTs bought:</span>
                        <span>{`${nftInfo.boughtAmount.toString()}/${nftInfo.amount.toString()}`}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NFTCard;